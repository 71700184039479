<template>
  <vx-card class="py-2">
    <div class="text-center">
      <h1>{{ question }}</h1>
    </div>

    <validation-observer v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="mt-4">
          <validation-provider
            v-slot="{ errors }"
            name="Feedback"
            rules="required"
          >
            <vs-textarea
              v-model="feedback"
              height="90px"
              class="mb-0"
              placeholder="Your feedback will be very helpful..."
            />
            <span
              v-if="errors.length"
              class="text-sm text-red-600"
            >
              Please provide some feedback.
            </span>
          </validation-provider>
        </div>

        <div class="flex justify-center mt-4">
          <vs-button
            color="success"
            button="submit"
          >
            Send
          </vs-button>
        </div>
      </form>
    </validation-observer>
  </vx-card>
</template>

<script>
import { http } from '@/ky';

export default {
  name: 'RjFeedbackForm',

  props: {
    surveyId: {
      type: Number,
      required: true,
    },

    question: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      feedback: null,
    };
  },

  methods: {
    async onSubmit() {
      const payload = {
        id: this.surveyId,
        feedback: this.feedback,
        status: 'Feedback',
      };
      await http.patch(`surveys/${this.surveyId}`, { json: payload });
      this.$emit('submited');
    },
  },
};
</script>
